import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../components/layout";

import Img from "gatsby-image";

export default function SocialImpact() {
  const data = useStaticQuery(graphql`
    {
      photos: allFile(
        filter: { relativeDirectory: { regex: "/^monday-motivation/" } }
        sort: { fields: name, order: DESC }
      ) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const photos = data["photos"].edges;

  //photos.sort((a, b) => new Date(b.node.name) - new Date(a.node.name));

  return (
    <Layout>
      <h2 className="section-header text-center">Monday motivation</h2>
      <div className="relative max-w-7xl m-auto px-5 pb-20">
        <div className="absolute inset-0">
          {/* <Img
            fluid={background}
            alt="background"
            className="h-full"
            imgStyle={{
              zIndex: -1,
              objectPosition: "0% 0",
              filter: "brightness(0.6)",
            }}
          /> */}
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-5 gap-10">
          {photos.map(({ node }, index) => (
            <div
              key={index}
              className={
                "h-full flex flex-col" +
                (node.childImageSharp.fluid.aspectRatio < 1
                  ? ""
                  : " sm:col-span-2")
              }
              
            >
              
              <Img
                fluid={node.childImageSharp.fluid}
                alt={index}
                className="flex-grow"
              />
              
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
